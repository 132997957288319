import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    background: "transparent",
  },

  content: {
    padding: 0,
    position: "relative",

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      marginTop: "56px",
    },
  },

  closeButton: {
    position: "absolute",
    zIndex: 99,

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      top: "28px",
    },
  },
});

const OrderDialog = (props) => {
  const { classes, closeButtonRight, showCloseButton, ...other } = props;

  return (
    <Dialog {...other} fullScreen classes={{ paper: classes.paper }}>
      <Fab
        onClick={props.onClose}
        aria-label="Close"
        className={classes.closeButton}
        style={{
          left: `${closeButtonRight}px`,
          visibility: showCloseButton ? "visible" : "hidden",
        }}
      >
        <CloseIcon />
      </Fab>
      <DialogContent classes={{ root: classes.content }}>{props.children}</DialogContent>
    </Dialog>
  );
};

OrderDialog.defaultProps = {
  fullWidth: true,
  closeButtonRight: 0,
};

OrderDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  closeButtonRight: PropTypes.number,
  showCloseButton: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(OrderDialog);
