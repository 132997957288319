import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "./Dialog";
import GForm from "./GForm";

const styles = (theme) => ({
  button: {
    background: "#53726d !important",
    borderRadius: 0,
    margin: "1em 0",
    color: `${theme.base.colors.text} !important`,
    textTransform: "uppercase",
    fontWeight: "bold",

    [`@media (min-width: ${theme.mediaQueryTresholds.M}px)`]: {
      padding: "1em 2em",
      margin: "1.5em 0",
    },
  },
});

class OrderButton extends React.Component {
  state = {
    open: false,
    closeButtonRight: 0,
    showCloseButton: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
      showCloseButton: false,
    });
  };

  handleFormLoad = (gform) => {
    const iframeRect = gform.getBoundingClientRect();

    setTimeout(() => {
      this.setState({
        closeButtonRight: iframeRect.right - 56,
        showCloseButton: true,
      });
    }, 50);
  };

  render() {
    const { classes, children, googleformid } = this.props;

    return (
      <div>
        <Button size="large" classes={{ root: classes.button }} onClick={this.handleClickOpen}>
          {children}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          closeButtonRight={this.state.closeButtonRight}
          showCloseButton={this.state.showCloseButton}
        >
          <GForm googleformid={googleformid} onLoad={this.handleFormLoad} />
        </Dialog>
      </div>
    );
  }
}

OrderButton.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  googleformid: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(OrderButton);
