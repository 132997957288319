import { graphql } from "gatsby";
import OrderTemplate from "./Order";

export const pageQuery = graphql`
  query OrderDataQuery($slug: String!, $bgImg: String) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        bgPosition
      }
    }
    image: file(relativePath: { eq: $bgImg }) {
      ...BackgroundImageFragment
    }
  }
`;

export default OrderTemplate;
