import React from "react";
import PropTypes from "prop-types";
import rehypeReact from "rehype-react";

import OrderButton from "./OrderButton";
import StoreButton from "./StoreButton";

import Layout from "../../components/Layout";
import Container from "../../components/Container";
import Background from "../../components/Background";
import Article from "../../components/Article";
import Content from "../../components/Article/Content";
import Header from "../../components/Article/Header";

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "order-button": OrderButton,
    "store-button": StoreButton,
  }
}).Compiler;

const OrderTemplate = ({ data, location }) => {
  const { page = {} } = data;
  const pageTitle = (page.frontmatter || {}).title;
  const bgPosition = page.frontmatter.bgPosition || "66% center";

  return (
    <Layout location={location}>
      <Container location={location} background={"#464445"}>
        <Background
          fluid={data.image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition={bgPosition}
        />

        <Article>
          <div className="order-panel">
            <Header title={pageTitle} />
            <Content style={{ marginBottom: 0 }}>{renderAst(page.htmlAst)}</Content>
          </div>
        </Article>
      </Container>
    </Layout>
  );
};

OrderTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default OrderTemplate;
