import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { withPrefix } from "gatsby";

const styles = () => ({
  root: {
    border: "0 !important",
  },

  label: {
    padding: "0.4em 0",
  },
});

const wrapStyle = {
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  margin: ".5rem .8rem",
};

const StoreButton = (props) => {
  const { classes, href, children } = props;

  return (
    <div style={wrapStyle}>
      <Fab classes={classes} href={href} target="_blank" rel="noopener">
        <img src={withPrefix(props["icon-src"])} alt={children} width="80%" />
      </Fab>
      {children}
    </div>
  );
};

StoreButton.propTypes = {
  classes: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
  "icon-src": PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(StoreButton);
