import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const getGformUrl = (id) => `https://docs.google.com/forms/d/e/${id}/viewform?embedded=true`;

const styles = (theme) => ({
  iframe: {
    position: "absolute",
    left: 0,
    right: 0,
    maxWidth: "600px",
    margin: "0 auto",
    overflow: "hidden",
    height: "100%",
  },
});

const GForm = (props) => {
  let iframe = React.createRef();
  const { classes, onLoad, googleformid } = props;

  return (
    <iframe
      ref={iframe}
      src={getGformUrl(googleformid)}
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
      scrolling="0"
      height="100%"
      width="100%"
      className={classes.iframe}
      onLoad={() => onLoad(iframe.current)}
    >
      Loading...
    </iframe>
  );
};

GForm.propTypes = {
  classes: PropTypes.object.isRequired,
  googleformid: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(GForm);
